// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alternative-lenders-tsx": () => import("./../../../src/pages/alternative-lenders.tsx" /* webpackChunkName: "component---src-pages-alternative-lenders-tsx" */),
  "component---src-pages-banks-tsx": () => import("./../../../src/pages/banks.tsx" /* webpackChunkName: "component---src-pages-banks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-bank-loan-tsx": () => import("./../../../src/pages/learn/bank-loan.tsx" /* webpackChunkName: "component---src-pages-learn-bank-loan-tsx" */),
  "component---src-pages-learn-business-line-of-credit-tsx": () => import("./../../../src/pages/learn/business-line-of-credit.tsx" /* webpackChunkName: "component---src-pages-learn-business-line-of-credit-tsx" */),
  "component---src-pages-learn-business-loan-tsx": () => import("./../../../src/pages/learn/business-loan.tsx" /* webpackChunkName: "component---src-pages-learn-business-loan-tsx" */),
  "component---src-pages-learn-credit-union-loan-tsx": () => import("./../../../src/pages/learn/credit-union-loan.tsx" /* webpackChunkName: "component---src-pages-learn-credit-union-loan-tsx" */),
  "component---src-pages-learn-invoice-factoring-loan-tsx": () => import("./../../../src/pages/learn/invoice-factoring-loan.tsx" /* webpackChunkName: "component---src-pages-learn-invoice-factoring-loan-tsx" */),
  "component---src-pages-learn-long-term-business-expansion-loan-tsx": () => import("./../../../src/pages/learn/long-term-business-expansion-loan.tsx" /* webpackChunkName: "component---src-pages-learn-long-term-business-expansion-loan-tsx" */),
  "component---src-pages-learn-sba-loan-tsx": () => import("./../../../src/pages/learn/sba-loan.tsx" /* webpackChunkName: "component---src-pages-learn-sba-loan-tsx" */),
  "component---src-pages-learn-term-loan-tsx": () => import("./../../../src/pages/learn/term-loan.tsx" /* webpackChunkName: "component---src-pages-learn-term-loan-tsx" */),
  "component---src-pages-learn-working-capital-loan-tsx": () => import("./../../../src/pages/learn/working-capital-loan.tsx" /* webpackChunkName: "component---src-pages-learn-working-capital-loan-tsx" */),
  "component---src-pages-loan-officers-tsx": () => import("./../../../src/pages/loan-officers.tsx" /* webpackChunkName: "component---src-pages-loan-officers-tsx" */),
  "component---src-pages-mission-statement-tsx": () => import("./../../../src/pages/mission-statement.tsx" /* webpackChunkName: "component---src-pages-mission-statement-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-strategic-partners-tsx": () => import("./../../../src/pages/strategic-partners.tsx" /* webpackChunkName: "component---src-pages-strategic-partners-tsx" */)
}

